<template>
  <div>
    <Loader text="Loading service" v-if="!hideLoader" />
    <KTPortlet v-else style="
      ">
      <template slot="body">
        <div class="loginBody" style="text-align: center; padding-top: 50px; padding-bottom: 50px">
          <h1 xzstyle="color: var(--dark)">
            You have no active {{ $route.params.serviceName || `${$route.query.serviceName}`.substring(0, 50) }} services to manage.
            <!-- available for {{ $route.params.serviceName || `${$route.query.serviceName}`.substring(0, 50) }}. -->
          </h1>
        </div>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["layoutConfig", "config", "isAuthenticated", "logoTheme"]),
    hideLoader() {
      return (this.$route.meta || {}).hideLoader === true;
    }
  },
  components: {
    KTPortlet,
    Loader
  }
};
</script>